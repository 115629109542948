<template>
  <div>
    <h3 align="center">
      <img
        class="rounded-circle mt-5"
        style="width: 200px;height: 200px"
        :src="$baseUploadURL + user.image"
        @error="$errorHandler"
      />
    </h3>
    <h3 class="text-white" align="center">
      مرحباً اكاديمية {{ user.userName }}
    </h3>

    <p style="color: #fff;text-align: center;">( {{ item.name }} )</p>
    <div class="container text-center mt-3 pt-5">
      <router-link :to="'/user/addYears/' + item.id" class="HomeBtn">
        اختيار المواليد والفرق المشاركه في الدورى
      </router-link>
      <router-link class="HomeBtn" :to="'/user/league/' + item.id">
        اضافه اللاعبين والجهاز الفنى والادارى
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        id: null,
        image: null,
        name: null,
      },
      id: null,
      user: JSON.parse(localStorage.user),
    };
  },
  methods: {
    getData() {
      this.$http.get(`league/${this.id}`).then(
        (response) => {
          this.item = response.data;
          localStorage.logoImage = this.item.image;
          this.$eventHub.$emit('image', this.item.image);
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getData();
    }
  },
};
</script>

<style>
.HomeBtn {
  display: inline-block;
  background-color: #0a8072;
  color: #fff;
  border-radius: 25px;
  width: 40%;
  padding: 15px;
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
}

.HomeBtn:hover {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}
</style>
